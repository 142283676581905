import React, {
  createContext,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Input, TextArea } from "components/Form";

import { send, sending } from "pages/Authentication/messages";
import Button from "components/Button";
import { sendErrorEmail, sendFeedbackEmail } from "utils";
import classNames from "classnames";
import {
  emailPlaceholder,
  sent,
  wantUsToGetInTouch,
} from "components/FormPlan/messages";
import { feedback, tellUs, whatIsYourFeedback } from "./messages";
import { useUser } from "contexts/User";

export const ReportErrorContext = createContext();

const FeedbackForm = ({ onMessageSent }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [message, setMessage] = useState("");
  const [contact, setContact] = useState("");

  const { name, email } = useUser();

  useEffect(() => {
    messageSent && setTimeout(() => onMessageSent(), 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageSent]);

  const onSend = useCallback(async () => {
    setIsLoading(true);

    try {
      await sendFeedbackEmail(message, contact, name, email);
      setTimeout(() => {
        setMessageSent(true);
        setIsLoading(false);
        setTimeout(() => setMessageSent(false), 3000);
      }, 1000);
    } catch (err) {
      setIsLoading(false);
      sendErrorEmail(err, `on trying to report error`);
    }
  }, [message, contact]);

  const onMessageChange = useCallback(({ target: { value } }) => {
    setMessage(value);
  }, []);

  const onContactChange = useCallback(({ target: { value } }) => {
    setContact(value);
  }, []);

  const onKeyDown = useCallback(
    ({ key }) => {
      key === "Enter" && onSend();
    },
    [onSend]
  );

  return (
    <div className="form">
      <h3 className="mb16">{feedback}</h3>

      <div className="form__row full-width mt16">
        <TextArea
          label={whatIsYourFeedback}
          fullWidth={true}
          placeholder={tellUs}
          onChange={onMessageChange}
        />
      </div>
      <div className="form__row full-width">
        <Input
          label={wantUsToGetInTouch}
          name="contact"
          type="text"
          placeholder={emailPlaceholder}
          onKeyDown={onKeyDown}
          onChange={onContactChange}
        />
      </div>
      <div className="form__row full-width">
        <Button
          size="medium"
          className={classNames("", {
            isLoading,
            fadeOut: messageSent,
          })}
          variation="square-rounded"
          fullWidth={true}
          theme="primary"
          disabled={isLoading}
          onClick={onSend}
        >
          {messageSent ? sent : isLoading ? sending : send}
        </Button>
      </div>
    </div>
  );
};

export default memo(FeedbackForm);
