import React, { memo, useCallback, useMemo, useState } from "react";
import MenuCard from "molecules/MenuCard";
import { useHistory } from "react-router-dom";
import { usePlan } from "components/FormPlan/Context";
import BuildPDF from "utils/BuildPDF";
import {
  downloadingPlanPdf,
  downloadPlanPdf,
  editPlan,
  yourPlan,
} from "../messages";
import { isIOSdevice, sendErrorEmail } from "utils";
import { useSnackbarNotification } from "components/SnackbarNotifications";
import { muteReminder } from "molecules/MuteReminder/messages";
import { MuteReminder } from "molecules";
import { useDispatch } from "react-redux";
import { toggleTrackDelays } from "store/actions/planv2";

const Plan = () => {
  const history = useHistory();
  const [downloading, setIsDownloading] = useState(false);
  const [buttonText, setButtonText] = useState(downloadPlanPdf);
  const { openGeneralErrorSnackbar } = useSnackbarNotification();
  const { plan } = usePlan();
  const dispatch = useDispatch();

  const downloadPDF = useCallback(() => {
    setIsDownloading(true);
    setButtonText(downloadingPlanPdf);

    try {
      const pdf = new BuildPDF();
      pdf.setArrayPlan(plan);

      pdf.build();

      setTimeout(() => {
        pdf.savePDF("Bible Journey");
        setIsDownloading(false);
        setButtonText(downloadPlanPdf);
      }, 1000);
    } catch (err) {
      setIsDownloading(false);
      setButtonText(downloadPlanPdf);
      sendErrorEmail(err, `on downloading pdf, planv2: ${plan}`);
      openGeneralErrorSnackbar();
    }
  }, [plan, openGeneralErrorSnackbar]);

  const items = useMemo(() => {
    const items = [
      {
        description: editPlan,
        pointer: true,
        onClick: () => history.push("/plan/edit"),
      },
    ];

    items.push({
      description: buttonText,
      className: "cursor-pointer",
      onClick: !downloading ? downloadPDF : () => {},
    });
    return [
      ...items,
      {
        description: muteReminder,
        pointer: true,
        onDescriptionClick: () => dispatch(toggleTrackDelays()),
        Component: <MuteReminder onlyToggler />,
      },
    ];
  }, [buttonText, dispatch, downloadPDF, downloading, history]);

  const menu = useMemo(
    () => ({
      header: {
        title: yourPlan,
      },
      items,
    }),
    [items]
  );

  return <MenuCard header={menu.header} items={menu.items} />;
};

export default memo(Plan);
