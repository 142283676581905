import axios from "axios";

export const urls = {
  bibleBrain: {
    base: "https://4.dbt.io",
    append: "?key=1462b719-42d8-0874-7c50-905063472458&v=4",
  },
  aBibliaDigital: {
    base: "https://www.abibliadigital.com.br",
    headers: {
      authorization:
        "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IlNhdCBNYXkgMjIgMjAyMSAwMTozMzowMSBHTVQrMDAwMC5hemFicmFhb0BnbWFpbC5jb20iLCJpYXQiOjE2MjE2NDcxODF9.uIH2EeNpCNPfv57jXcPwJX0oBIBBXfI1jpkHp4mu0VU",
    },
  },
};

const endPointsTemplate = {
  bibleBrain: async ({ bibleId, book, chapter }) => {
    return axios.get(
      `${urls.bibleBrain.base}/api/bibles/filesets/${bibleId}/${book}/${chapter}${urls.bibleBrain.append}`
    );
  },
  aBibliaDigital: async ({ book, chapter }) => {
    return await axios.get(
      `${urls.aBibliaDigital.base}/api/verses/nvi/${book}/${chapter}`,
      {
        headers: { ...urls.aBibliaDigital.headers },
      }
    );
  },
};

export const biblesArray = [
  {
    language: "Português",
    versions: [
      {
        name: "Nova Versão Internacional",
        abbr: "NVI",
        id: "NVI",
        origin: "aBibliaDigital",
        endpoint: endPointsTemplate.aBibliaDigital,
        downloadAvailable: true,
      },
      {
        name: "Almeida Corrigida Fiel",
        id: "PORACF",
        abbr: "ACF",
        origin: "bibleBrain",
        endpoint: endPointsTemplate.bibleBrain,
        downloadAvailable: true,
      },
      {
        name: "Tradução Inter-confessional da Bíblia",
        id: "PORBSP",
        abbr: "BSP",
        origin: "bibleBrain",
        endpoint: endPointsTemplate.bibleBrain,
        downloadAvailable: true,
      },
      {
        name: "João Ferreira de Almeida Atualizada",
        id: "PORFAA",
        abbr: "FAA",
        origin: "bibleBrain",
        endpoint: endPointsTemplate.bibleBrain,
        downloadAvailable: true,
      },
      {
        name: "João Ferreira de Almeida",
        id: "PORJFA",
        abbr: "JFA",
        origin: "bibleBrain",
        endpoint: endPointsTemplate.bibleBrain,
        downloadAvailable: true,
      },
    ],
  },
  {
    language: "English",
    versions: [
      {
        name: "American Standard Bible",
        id: "ENGASV",
        abbr: "ASV",
        origin: "bibleBrain",
        endpoint: endPointsTemplate.bibleBrain,
        downloadAvailable: true,
      },
      {
        name: "English Standard Version",
        id: "ENGESV",
        abbr: "ESV",
        origin: "bibleBrain",
        endpoint: endPointsTemplate.bibleBrain,
        downloadAvailable: true,
      },
      {
        name: "English Version for the Deaf",
        id: "ENGEVD",
        abbr: "EVD",
        origin: "bibleBrain",
        endpoint: endPointsTemplate.bibleBrain,
        downloadAvailable: true,
      },
      {
        name: "King James Version",
        id: "ENGKJV",
        abbr: "KJV",
        origin: "bibleBrain",
        endpoint: endPointsTemplate.bibleBrain,
        downloadAvailable: true,
      },
      {
        name: "New American Standard Bible (NASB)",
        id: "ENGNAS",
        abbr: "NAS",
        origin: "bibleBrain",
        endpoint: endPointsTemplate.bibleBrain,
      },
      {
        name: "Revised Version 1885",
        id: "ENGREV",
        abbr: "REV",
        origin: "bibleBrain",
        endpoint: endPointsTemplate.bibleBrain,
      },
      {
        name: "World English Bible",
        id: "ENGWEB",
        abbr: "WEB",
        origin: "bibleBrain",
        endpoint: endPointsTemplate.bibleBrain,
      },
      {
        name: "World Messianic Version",
        id: "ENGWM1",
        abbr: "WM1",
        origin: "bibleBrain",
        endpoint: endPointsTemplate.bibleBrain,
      },
      {
        name: "Wycliffe Modern",
        id: "ENGWMV",
        abbr: "WMV",
        origin: "bibleBrain",
        endpoint: endPointsTemplate.bibleBrain,
      },
      {
        name: "World English Bible - Winfred Henson",
        id: "EN1WEB",
        abbr: "WEB",
        origin: "bibleBrain",
        endpoint: endPointsTemplate.bibleBrain,
      },
    ],
  },
  {
    language: "Indonesia",
    versions: [
      {
        name: "Shellabear NT Kitab Suci Injil (Terjemahan 1912)",
        id: "INDALA",
        abbr: "ALA",
        origin: "bibleBrain",
        endpoint: endPointsTemplate.bibleBrain,
        downloadAvailable: true,
      },
      {
        name: "Indonesian New Translation (formal) Version, 1974",
        id: "INDASV",
        abbr: "ASV",
        origin: "bibleBrain",
        endpoint: endPointsTemplate.bibleBrain,
      },
      {
        name: "Indonesian New Translation (formal) Version, 1974",
        id: "INZNTV",
        abbr: "NTV",
        origin: "bibleBrain",
        endpoint: endPointsTemplate.bibleBrain,
      },
      {
        name: "Indonesian Sehari-hari",
        id: "INDSHV",
        abbr: "SHV",
        origin: "bibleBrain",
        endpoint: endPointsTemplate.bibleBrain,
      },
    ],
  },
  {
    language: "Polsky",
    versions: [
      {
        name: "1632 Polish Biblia Gdanska",
        id: "POLPBG",
        abbr: "PBG",
        origin: "bibleBrain",
        endpoint: endPointsTemplate.bibleBrain,
      },
    ],
  },
];
