import api from "./api";

const GITHITHUB_TOKEN = process.env.REACT_APP_GITHUB_TOKEN;

const sendLocalStorageToGist = async (token, data) => {
  const localStorageContent = localStorage.getItem("persist:root");
  JSON.parse(localStorageContent);

  const gistData = {
    description: `${data ? data : "Anônimo"} - Backup do localStorage`,
    public: false,
    files: {
      "localStorageBackup.json": {
        content: localStorageContent,
      },
    },
  };

  try {
    const response = await fetch("https://api.github.com/gists", {
      method: "POST",
      headers: {
        Authorization: `token ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(gistData),
    });

    if (response.ok) {
      const result = await response.json();
      console.log("Gist ===> 🚀");
      return result.html_url;
    } else {
      const error = await response.json();
      console.error("Error ===> ", error);
    }
  } catch (err) {
    console.error("Err ===> ", err);
  }
};

const sendDiscordNotification = async (type, message, contact) => {
  try {
    await api.post("/service/send-nofication", {
      type,
      message,
      contact,
    });
  } catch (error) {
    console.error(
      "Error ao enviar notificação ==>",
      error.response?.data || error.message
    );
  }
};

export const sendErrorEmail = async (error, where) => {
  const gistUrl = await sendLocalStorageToGist(GITHITHUB_TOKEN, where);
  const errorDetails = `
  ## Erro:
  ${error}

  __**Local:**__
  ${where}

  __**Idioma:**__
  ${localStorage.i18nextLng}

  __**User Agent:**__
  ${window.navigator.userAgent}

  __**Host:**__
  ${window.location.href}

  __**Link Gists com LocalStorage:**__
  ${gistUrl}
  `;

  await sendDiscordNotification("ERROR_APP", errorDetails, where);
};

export const sendErrorReportingEmail = async (
  message,
  contact,
  name,
  email
) => {
  const gistUrl = await sendLocalStorageToGist(GITHITHUB_TOKEN, name);

  const isLoggedIn = name && email;

  const userInfo = isLoggedIn
    ? `
## Usuário logado!

**Nome:** ${name}
**E-mail:** ${email}
`
    : "** ## ⚠ Usuário não logado**";

  const errorDetails = `
${userInfo}

**Mensagem:**
  ${message}

**Contato:**
 ${contact ? contact : "*Não informado*"}

**Informações do Usuário:**
  **Idioma:** ${localStorage.i18nextLng}
  **Navegador:** ${window.navigator.userAgent}
  **Origem:** ${window.location.href}

  **Link para LocalStorage:** ${gistUrl}
  `;

  await sendDiscordNotification("REPORTING", errorDetails, name);
};

export const sendFeedbackEmail = async (message, contact, name, email) => {
  const gistUrl = await sendLocalStorageToGist(GITHITHUB_TOKEN, name);

  const isLoggedIn = name && email;

  const userInfo = isLoggedIn
    ? `
## Usuário logado!

**Nome:** ${name}
**E-mail:** ${email}
`
    : "** ## ⚠ Usuário não logado**";

  const feedbackDetails = `

${userInfo}

**Mensagem:**
  ${message}

**Contato:**
 ${contact ? contact : "*Não informado*"}

**Informações do Usuário:**
  **Idioma:** ${localStorage.i18nextLng}
  **Navegador:** ${window.navigator.userAgent}
  **Origem:** ${window.location.href}

  **Link para LocalStorage:** ${gistUrl}
  `;

  await sendDiscordNotification("FEEDBACK_APP", feedbackDetails, name);
};

export const sendEmail = async (subject, message) => {
  const gistUrl = await sendLocalStorageToGist(GITHITHUB_TOKEN, subject);
  const sendEmailDetails = `
  __**Mensagem:**__
  ${message}

  __**Contato:**__
  ${subject ? subject : "*Não informado*"}

  __**Idioma:**__
  ${localStorage.i18nextLng}

  __**User Agent:**__
  ${window.navigator.userAgent}

  __**Host:**__
  ${window.location.href}

  __**Link Gists com LocalStorage:**__
  ${gistUrl}
  `;

  await sendDiscordNotification("SEND_EMAIL", sendEmailDetails, subject);
};
