import React, { useCallback, useState } from "react";

import { notAcceptedYet, checkEmail } from "./messages";
import { ReactComponent as Podium } from "assets/images/podium.svg";
import Button from "components/Button";
import { mobileText, shareBibleJourney } from "pages/Menu/messages";
import { copyText, isMobileDevice } from "utils";
import { textCopied } from "translate/globalMessages";

const NoParticipants = () => {
  const [textIsCopied, setTextIsCopied] = useState(false);

  const handleShareBibleJourney = useCallback(() => {
    const url = "https://download-bibleplan.netlify.app/";

    if (!isMobileDevice) {
      copyText(url);
      setTimeout(() => {
        setTextIsCopied(false);
      }, 4000);
      return setTextIsCopied(true);
    }

    const text = mobileText;

    navigator.share({
      title: "Bible Journey",
      text,
      url,
    });
  }, []);

  return (
    <div className="invite-not-accepted">
      <Podium />
      <h3>{notAcceptedYet}</h3>
      <p>{checkEmail}</p>

      <Button
        size="medium"
        className="color-white mt8"
        variation="square-rounded"
        theme={textIsCopied ? "success" : "primary"}
        onClick={handleShareBibleJourney}
      >
        {textIsCopied ? textCopied : shareBibleJourney}
      </Button>
    </div>
  );
};

export default NoParticipants;
